//Popup
$("#popup .close, #popup-trigger").click(function(){
	$("#popup").toggleClass('closed');
	$.cookie("popup", "seen");
});

$("#popup form").submit(function(){
    $.cookie("popup", "submitted", { expires: 365 });
});

$(document).ready(function () {
	if($.cookie("popup") != "seen" && $.cookie("popup") != "submitted"){
		$("#popup").removeClass('closed');
	}

	if($("#paymentForm").length){
		$("#paymentForm").submit();
	}
});

//Image shifter prod-inner
$('.prod-images img').hover(function(){
	$(this).closest('.prod-inner').find('.main-image').attr('src',$(this).attr('data-src'));
	$('.prod-images img').removeClass('active');
	$(this).addClass('active');
});

$(window).scroll(function () {
	if ($(this).scrollTop() < 100) {
		$( "#header" ).removeClass('scroll');
	} else {
		$( "#header" ).addClass('scroll');
	}
});

//Unpaid order
$("#unpaid-order .close").click(function(){
	$.cookie("unpaid","closed");
});
if($.cookie("unpaid") != "closed"){
	$("#unpaid-order").slideDown();
}

//Basket trigger
$(document).ready(function () {
    $('.basket-trigger').click(function () {
        console.log()
	    $('#small-basket-drop').slideToggle();
	    $(this).find('.fa-chevron-down, .fa-chevron-up').toggleClass('fa-chevron-up fa-chevron-down');
    });
});

//Slidedown når du lægger i kurven
$(document).ready(function () {
    var url = window.location.href;
    var lastPart = url.substr(url.lastIndexOf('#') + 1);
    if (lastPart === "added") {
	    $('#small-basket-drop').slideDown();
	    $('#small-basket-drop').delay(2000).slideUp();
    }
});

//Voucher trigger
$('.toggle-voucher').click(function(){
	$('#voucher-input').removeClass('hidden');
	$(this).addClass('hidden');
});
$('.close-voucher').click(function(){
	$('#voucher-input').addClass('hidden');
	$('.toggle-voucher').removeClass('hidden');
});

//New delivery 
$('#new_del').change(function(){
	if(this.checked){
		$('#new-deliv').slideDown();
	} else {
		$('#new-deliv').slideUp();
	}
});

//Size chooser
$('.prod-sizes li').click(function(){
	$(this).closest(".prod").find(".attribute").val($(this).attr('data-value'));
	$(this).parent("ul").children("li").removeClass("active");
	$(this).addClass("active");
});
$(function() {
	$('.prod-sizes').each(function(){
		$(this).children("ul").children("li").first().addClass("active");
	});
});

//Basket amount submit
$('.basket-form input').change(function(){
	$(this).closest('form').submit();
});

$(document).ready(function(){
	if($(".filterbox").length){
		createFilters();
	}
});

function createFilters(){
	var sizes = [];
	var gender = [];
	var brands = [];
	var types = [];

	var sizeHtml = "";
	var genderHtml = "";
	var brandHtml = "";
	var typeHtml = "";

	$(".prod").each(function(){
		if(sizes.indexOf($(this).data("size")) == -1){
			sizes.push($(this).data("size"));
		}
		if(gender.indexOf($(this).data("gender")) == -1){ 
			gender.push($(this).data("gender"));
		}
		if(brands.indexOf($(this).data("brand")) == -1){
			brands.push($(this).data("brand"));
		}
		if(types.indexOf($(this).data("type")) == -1){
			types.push($(this).data("type"));
		}
	});

	function alphanum(a, b) {
		function chunkify(t) {
			var tz = [], x = 0, y = -1, n = 0, i, j;

			while (i = (j = t.charAt(x++)).charCodeAt(0)) {
				var m = (i == 46 || (i >=48 && i <= 57));
				if (m !== n) {
					tz[++y] = "";
					n = m;
				}
				tz[y] += j;
			}
			return tz;
		}

		var aa = chunkify(a);
		var bb = chunkify(b);

		for (x = 0; aa[x] && bb[x]; x++) {
			if (aa[x] !== bb[x]) {
				var c = Number(aa[x]), d = Number(bb[x]);
				if (c == aa[x] && d == bb[x]) {
					return c - d;
				} else {
					return (aa[x] > bb[x]) ? 1 : -1;
				}
			}
		}
		return aa.length - bb.length;
	}

	sizes.sort(alphanum);
	gender.sort(alphanum);
	brands.sort(alphanum);
	types.sort(alphanum);

	for (i = 0; i < gender.length; i++) { 
		genderHtml += '<label>'+gender[i]+'<input type="checkbox" value="'+gender[i].replace("-"," ")+'"></label>';
	}

	for (i = 0; i < sizes.length; i++) { 
		sizeHtml += '<label>'+sizes[i].replace("-"," ")+'<input type="checkbox" value="'+sizes[i]+'"></label>';
	}

	for (i = 0; i < brands.length; i++) { 
		brandHtml += '<label>'+brands[i].replace("-"," ")+'<input type="checkbox" value="'+brands[i]+'"></label>';
	}

	for (i = 0; i < types.length; i++) { 
		typeHtml += '<label>'+types[i].replace("-"," ")+'<input type="checkbox" value="'+types[i]+'"></label>';
	}

	$(".filter-gender").html(genderHtml);
	$(".filter-size").html(sizeHtml);
	$(".filter-brand").html(brandHtml);
	$(".filter-type").html(typeHtml);

	$(".filterbox input").change(function(){

		var noneChecked = true;

		$(".prod").parent().hide();

		$("input:checkbox").each(function(){
			if(this.checked){
				var target = "."+$(this).val();
				$(target).parent().show();
				noneChecked = false;
			}
		});

		if(noneChecked){
			$(".prod").parent().show();
		}
	});

};

$("#toggleFilters").click(function(){
	$(".filterbox").toggleClass("closed");

	$(".filterbox .fa").toggleClass("fa-angle-double-down").toggleClass("fa-angle-double-up");

	var text = $(this).find("span").text();
	$(this).find("span").text(text == "Se alle filtrer" ? "Se færre filtrer" : "Se alle filtrer");


});

$(".validate").validate({
	errorClass: "alert alert-danger",
	errorLabelContainer: "#error-container",
	rules: {
		//Faktura adresse
		order_name: "required",
		order_address: "required",
		order_zipcode: "required",
		order_city: "required",
		phone: {required: true, number: true, minlength: 8 },
		email: {required: true, email: true },
		order_country_id: "required",
		// Anden leveringsadresse
		del_name: "required",
		del_address: "required",
		del_zipcode: "required",
		del_city: {required: true, accept: "[A-ZÆØÅa-zæøå_ ][^0-9]" },
		extra: "required",
		vilkar: "required"
	}, 
	messages: {
		//Faktura adresse
		order_name: "Indtast dit navn",
		order_address: "Indtast din adresse",
		order_zipcode: "Indtast et postnummer",
		order_city: "Indtast en by",
		phone: {required: "Indtast dit nummer", number: "Må kun indeholde tal", minlength: "Indtast et korrekt nummer" },
		email: {required: "Indtast din email", email: "Indtast en korrekt email" },
		order_country_id: "Vælg et land",
		// Anden leveringsadresse
		del_name: "Indtast leveringsnavnet",
		del_address: "Indtast leveringsadressen",
		del_zipcode: "Indtast et postnummer",
		del_city: "Indtast en by",
		extra: "Indtast et navn",
		vilkar: "Accepter vores betingelser"
	}
});